* {
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    font-size: 17px;
}

/* input */
.alert-home {
    position: absolute;
    top: 150px
}

.text-area {
    border: 1px solid #c9c9c9;
    border-radius: 25px;
    overflow: hidden;
    height: 150px;
}

.quill {
    height: 110px;
}

.input-home {
    border: 1px solid #c9c9c9;
    background-color: #fff;
    border-radius: 25px;
}

/* Images */

.img-home,
.form-home {
    width: 50%;
    overflow: hidden;
}

.img {
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-strong {
    font-size: 20px;
}

.text-img {
    padding: 20px;
    font-size: 20px;
}

/* Buttons */
.btn-home {
    background-color: #fff;
    color: #000;
    width: 100%;
    border-radius: 25px;
    border: none;
}

.btn-home:active {
    background-color: #d84b36;
}

.form-check-input {
    border: #000 1px solid;
}

/* Spans */
.span-home {
    color: #d84b35;
    font-size: 14px;
}

/* checkbox */
.form-check-input:checked {
    background-color: #d84b35;
    border: #d84b35;
}

/* alert */
.alert-home {
    z-index: 1000;
}

.text {
    font-size: 16px;
    color: #000;
}

.btn-close {
    position: absolute;
    font-size: smaller;
    margin: 0;
    padding: 0;
    right: 8px;
    top: 8px
}

/* formulário */

.form-home {
    clip-path: polygon(20% 0, 100% 0, 100% 100%, 0% 100%);
    background-color: #F26651;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-home .formulario {
    width: 70%;
}

.formulario {
    z-index: 1;
    width: 100%;
    height: auto;
    margin-right: 9%;
    overflow: hidden;
}

.footer {
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.div-editor {
    background-color: #fff;
    min-height: 200px;
}

.files {
    margin-top: 10px;
}

.alert-home {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1000;
}

/* Responsivity */

@media (max-width: 700px) {

    .img {
        display: none;
    }

    .img-home {
        display: none !important;
    }

    .form-home {
        background-color: #F26651;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        clip-path: none;
    }

    .form-home .formulario {
        width: 80%;
        overflow: hidden;
    }

    .formulario {
        z-index: 1;
        width: 100%;
        height: 100%;
        margin-right: 9%;
    }
}