.background {
    padding: 0;
    margin: 0;
    background-image: url(../img/background.png);
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
}

.loading-login {
    z-index: 1000;
    position: absolute;
    background-color: rgba(8, 8, 8, 0.3);
}

.form-login {
    border: 1px black solid;
    border-radius: 15px;
    /* width: 50%; */
}

.input-login {
    border: 1px rgb(28, 14, 14) solid;
    border-radius: 25px;
}

.btn-login {
    background-color: #d84b36;
    border: #F26651;
    color: #fff;
    width: 60%;
    border-radius: 25px;
}

.btn-login:active {
    background-color: #F26651;
}

.error-msg{
    color:red;
}

@media (max-width: 1000px) {
    .form-login {
        border: 1px black solid;
        width: 60%;
    }
}