.btn-upload {
    background-color: transparent;
    border: none;
    border: 2.3px dotted rgb(255, 255, 255);
    border-radius: 25px;
    height: 60px;
}

.files {
    margin: 10px;
    height: 90px;
    overflow: auto;
    overflow-x: hidden;
}

.list-group-item-action {
    color: #000;
}

.close-btn {
    background-color: transparent;
    border: none;

}

.paragrafo-upload {
    display: flex;
    justify-content: space-between;
    margin: 1px;
    font-size: 16px;
}

.svg {
    color: rgb(102, 102, 102);
    font-size: 25px;
}